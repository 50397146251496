@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Montserrat', sans-serif;
  background-color: black;
  font-size: 1.2rem;
  font-weight: 500;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.outer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(135deg, #ffffff 0%, #c3e0e2 100%); */
  background: #000;
  /* border-width: 16px;
  border-image-slice: 1;
  border-style: solid;
  border-image-source: linear-gradient(to bottom, #83a4d4, #b6fbff); */
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.imageCont {
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageCont img {
  width: 100%;
  max-width: 360px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  justify-content: center;
}

.wrapper {
  padding: .5rem;
  /* max-height: 100vh; */
  min-height: 100vh;
  /* height: 100vh; */
  max-width: 768px;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.2rem;
  text-align: center;
}

.heading {
  color: #262534;
  font-size: 2rem;
  font-weight: 800;
  background: linear-gradient(to top, #ffd335 0%, #b08f4c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text {
  color: #b5b5b5;
}



button {
  border-radius: 2rem;
  border: 1px solid #ffefb0;
  background: inherit;
  color: #ffefb0;
  padding: .6rem 2rem .6rem 2rem;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  filter: brightness(90%);
}

::selection {
  background-color: #263238;
  color: #FCFCFC;

}

.overlay {
  text-align: center;
  position: fixed;
  font-size: 1.5rem;
  color: whitesmoke;
  font-weight: 700;
  z-index: 10;
  top: 0;
  left: 0;
}

.overlay p>span {
  font-weight: initial;
  font-size: 1.1rem;
}

.overlay>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  border: 2px solid;
  min-height: 100vh;
  min-width: 100vw;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  background: whitesmoke;
  user-select: none;
  color: rgb(34, 34, 34);
}

.close:hover {
  transform: scale(1.05);
}

.success {
  background: #68c3a3;
}

.text-logo {
  font-size: 5.5rem;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.failure-text {
  color: #f03434;
}

.error-text {
  color: #24252a;
}

.success-text {
  color: #68c3a3;
}

.failure {
  background: #f03434;
}

.error {
  background: #24252a;
}

:root {
  scrollbar-width: none;
  scrollbar-color: #636E72 transparent;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-thumb {
  background-color: #636E72;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2D3436;
}

body {
  overflow: overlay;
}

.credits {
  margin-top: 1rem;
  padding: 4px;
  width: 100%;
  background: #111111;
  color: #fff6c0;
  text-align: center;
  font-size: 14px;
}

.credits>span {
  color: #f64747;
}